import axios from "axios";
const SEGMENT_PLAYERS_PROD = "C0F7F3584CA98276";
const PROD_ID ="8C50F";
const AUTH_PROD = "6JOOBBEKRJKOYG8P4XBNK8YGB8U1MB8BPOKPT3OQFWJWFTMBOC"

export const axiosInstance = axios.create({
    baseURL: `https://${PROD_ID}.playfabapi.com/`,
    timeout: 10000,
    headers: {
        'X-Authorization': AUTH_PROD,
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'X-SecretKey': AUTH_PROD,
    }
});

export async function getAllPlayers() {
    const datas = {
        "SegmentId": SEGMENT_PLAYERS_PROD,
        "SecondsToLive": 5,
        "MaxBatchSize": 500
    }
    try {
       const resp = await axiosInstance.post('Admin/GetPlayersInSegment', datas);
       return resp.data;
    } catch (error) {
        console.error("no player in the list", error);
    }
}

export async function updatePlayerData(datas,id) {
    console.log("datas updated", datas)
    
    const updatedDatas = {
        PlayFabId: id,
        Data: datas,
        Permission: "Private",
    }
    try {
        const resp = await axiosInstance.post('Admin/UpdateUserData', updatedDatas);
        return resp.data;
    } catch (error) {
         console.error("Une erreur s'est produite, veuillez recommencer.", error);
    }
}

export default async function getPlayerData(PlayFabId) {
    const datas = {
        "PlayFabId": PlayFabId,
    }

    try {
       const resp = await axiosInstance.post('Admin/GetUserData', datas);
       return resp.data;
    } catch (error) {
        console.error("no data user", error);
    }
}

