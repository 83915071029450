import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import ganLogo from '../../assets/images/gan-detour.png';
import googleLogo from '../../assets/images/logo-google.png';
 import { auth, signInWithGoogle } from '../../Firebase/config.mjs';
 import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from 'react-toastify';
import Spinner from '../Loaders/Spinner';

const LoginPage = () => {
  const [user, loading] = useAuthState(auth);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (user) {
        if (user.email.endsWith('croozy.studio')) {
                navigate("/dashboard");
        } else {
            toast.error("Vous n'avez pas l'autorisation d'accéder à cette application")
            navigate("/");
        }
    }
  }, [user, loading, showLoader, navigate])

  const handleOnClickConnexion = () => {
      setShowLoader(true);
      signInWithGoogle()
  }
  
 
    return ( 
        <section className="w-full h-screen flex flex-row justify-center items-center bg-blue-400">
            {!user && showLoader ? <Spinner/> : (
                <article className='flex flex-row justify-center items-center h-2/3 shadow-lg shadow-gray-500/50 w-3/4'>
                <div className="flex flex-col justify-center w-1/2 h-full bg-black">
                    <div className='flex flex-col justify-center items-center'>
                         <img src={googleLogo} alt="logo google" className="w-1/3"/>
                    </div>
                    <div className='flex flex-row justify-center'>
                         <button onClick={handleOnClickConnexion} className="bg-gray-700 text-white font-bold w-52 rounded-md p-3">Connexion avec Google</button>
                    </div>
                </div>
                <div className="flex flex-col justify-center w-1/2 h-full bg-white">
                    <div className='flex flex-col justify-center items-center -mt-12'>
                         <img src={ganLogo} alt="logo picolors" className="w-2/3"/>
                          <h1>Front admin</h1>
                    </div>
                   
                </div>
            </article>
            )}
            
        </section>
     );
}
 
export default LoginPage;