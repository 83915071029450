import * as firebase from 'firebase/app';
import {
  GoogleAuthProvider, 
  getAuth,
  signOut,
  setPersistence,
  browserSessionPersistence,
  signInWithPopup,
} from 'firebase/auth';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL  } from "firebase/storage";
import { getOneJsonGrid } from '../Utils/dailygrids.mjs';

const firebaseConfig = {
  apiKey: "AIzaSyAyZgEHhDvmLCwcAPpn_7vYZSNo5_G6zV8",
  authDomain: "picolors-frontadmin.firebaseapp.com",
  projectId: "picolors-frontadmin",
  storageBucket: "picolors-frontadmin.appspot.com",
  messagingSenderId: "318364722411",
  appId: "1:318364722411:web:b9600ee5f479379e9c8aae"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);



const signInWithGoogle = async () => {

  await setPersistence(auth, browserSessionPersistence).then(() => {
    const googleProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleProvider).then(async (res) => {
      const user = res.user;
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      if (docs.length === 0) {
        addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
        });
      }
    }).catch(err => {
      console.error(err);
      alert(err.message);
    });
  })
  
};

const logout = () => {
  signOut(auth);
};

// Enregistrer l'image de la grille dans Cloud Firestore :
const addImageToCloudFirestore = async (pic) => {
  const timestamp = db.FieldValue.serverTimestamp();

  const data = {
    image: pic,
  };

// Add a new document in collection "Islands"
await setDoc(doc(db, "Islands", timestamp), data);
  
};



// Enregistrer les grilles dans Cloud Storage:
const uploadFilesInCloudStorage = async (blob, folder) => {
  const gridsRef = ref(storage, `Islands/${folder}/${blob.name}`);
  if (blob) {
   // console.log({"blob:": blob, "folder": folder})
   //Recuperation du fichier gitlab
    await getOneJsonGrid(folder, blob.name).then(res => {
      //console.log("response", res.data)
      //Récupération du content format base64 puis transformé en JSON:
      if (res.data.content) {
        const string = res.data.content;
        const buffer = Buffer.from(string, "base64");
        //console.log("Buffer:", buffer)
        //console.log('res data', string);
      uploadBytes(gridsRef, buffer).then((snapshot) => {
        //console.log("snapshot", snapshot)
        return getDownloadURL(snapshot.ref)
      }).then(async (url) => {
      
        let datas;
        
        if (blob.name.endsWith('.json')) {
          datas = {
            name: blob.name,
            path: url,
            location: folder,
            type: 'json'
          }
        }
        if (blob.name.endsWith('.png')) {
          datas = {
            name: blob.name,
            path: url,
            location: folder,
            type: 'png'
          }
        }
       
          //console.log('data',datas)
        
       
        //console.log("url:", url);
      // Ajouter les fichiers a Firestore
      await addDoc(collection(db, "Islands"),datas);
       
      }).catch(error => console.error(error))
      }
 
   
     
    })
     
   
    // await gridsRef
    //   .put(path)
    //   .catch((error) => {
    //   throw error;
    //   })
    //   .catch((error) => console.error(error));

  // await gridsRef
  //     .getDownloadURL()
  //     .then((urlres) => {
  //     console.log('url:', urlres);
  //     addImageToCloudFirestore(urlres);
  //     })
  //     .catch((error) => {
  //     throw error;
  //     });
  }
  
}

const getGridsFromIslands = async () => {
  let allFiles = [];
  const querySnapshot = await getDocs(collection(db,'Islands'));
  querySnapshot.forEach(doc => {
    //console.log("doc", doc.data());
    const file = doc.data();
    allFiles.push(file);
  })
  return allFiles;
}

// The ID of your GCS bucket
const bucketName = 'picolors-frontadmin.appspot.com';

// The origin for this CORS config to allow requests from
const origin = 'http://picolors-frontadmin.appspot.com';

// The response header to share across origins
const responseHeader = 'Content-Type';

// The maximum amount of time the browser can make requests before it must
// repeat preflighted requests
const maxAgeSeconds = 3600;

// The name of the method
// See the HttpMethod documentation for other HTTP methods available:
// https://cloud.google.com/appengine/docs/standard/java/javadoc/com/google/appengine/api/urlfetch/HTTPMethod
const method = 'GET';

const configureBucketCors = async () => {
  await storage.bucket(bucketName).setCorsConfiguration([
    {
      maxAgeSeconds,
      method: [method],
      origin: [origin],
      responseHeader: [responseHeader],
    }
  ]);
  console.log(`Bucket ${bucketName} was updated with a CORS config
      to allow ${method} requests from ${origin} sharing
      ${responseHeader} responses across origins`);
}

export {
  auth,
  db,
  signInWithGoogle,
  logout,
  uploadFilesInCloudStorage,
  firebase,
  getGridsFromIslands,
  configureBucketCors
};

export default uploadFilesInCloudStorage;
