import React from 'react';

const Spinner = () => {
    return ( 
        <div className="flex items-center justify-center">
            <div style={{borderTopColor: "transparent"}} className="spinner-border animate-spin inline-block w-12 h-12 border-4 border-red-400 border-solid rounded-full" role="status" viewBox="0 0 24 24">
        </div>
        </div>
     );
}
 
export default Spinner;