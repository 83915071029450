import React, {useEffect, useState} from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from "./Components/HomePage";
import LoginPage from "./Components/LoginPage/LoginPage";
import {AuthContext} from "./Context/AuthContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, configureBucketCors, getGridsFromIslands } from './Firebase/config.mjs';
import Nav from './Components/Nav';
import PlayerPage from "./Components/Player/PlayerPage";
import FoldersPage from "./Components/FoldersPage";
import GridsByFolder from "./Components/GridsByFolder";

const App = () => {
  const [allGrids, setAllGrids] = useState([]);
  const [user, loading] = useAuthState(auth);

    useEffect(() => {
      
      //TODO: Filtrer les Dailygrids

      //Récupération des grilles dans Firebase
      getGridsFromIslands().then(res => setAllGrids(res))
    }, [user, loading, allGrids, setAllGrids]);
  



  const value = {

  }
  return (
    <div className="App">
      <AuthContext.Provider value={value}>
        <BrowserRouter>
               {user ? <Nav/> : null}
              <Routes>
                <Route exact path="/" element={<LoginPage/>}/>
                <Route exact path="/dashboard" element={<HomePage/>} />
                <Route exact path="/player/:id" element={<PlayerPage />} />
                <Route path="grids">
              <Route path="" element={<FoldersPage allGrids={ allGrids } />}/>
                  <Route path=":island" element={<GridsByFolder allGrids={ allGrids }/>}/> 
                </Route>
               
              </Routes>
        </BrowserRouter>
     </AuthContext.Provider>
     <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default App;
