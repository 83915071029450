import axios from "axios";
import { BlobServiceClient } from '@azure/storage-blob';
import { GitLabAxiosInstance, PFaxiosInstanceProd } from "./axiosInstance.mjs";
import uploadFilesInCloudStorage from "../Firebase/config.mjs";

/* ************************
 * AZURE CONNECTION
**************************/

const account = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;

const blobServiceClt = new BlobServiceClient(`https://${account}.blob.core.windows.net`)
/* ************************
 * GITLAB API
**************************/
export function handledailygrids () {
    console.log("hello grids")
    
    try {
       const resp = GitLabAxiosInstance.get(`tree/?ref=develop&path=Assets/StreamingAssets/grids/DailyGrids/2021_11/`);
       return resp;
    } catch (error) {
        console.log(error)
    }
}

export function getOneJsonFile(file) {
    try {
        const resp = GitLabAxiosInstance.get(`files/${file}?ref=develop`);
        return resp;
    } catch (error) {
         console.log(error)
    }
}

export function getOneJsonGrid(island, filename) {
    try {
        const resp = GitLabAxiosInstance.get(`files/Assets%2FStreamingAssets%2Fgrids%2FIslands%2F${island}%2F${filename}?ref=develop`);
        return resp;
    } catch (error) {
         console.log(error)
    }
}

//Permet de repérer le nombre de dossiers "Island" qui ont été créé depuis Gitlab:
export function getIslandTree () {
    try {
        const resp = GitLabAxiosInstance.get(`tree/?ref=develop&path=Assets/StreamingAssets/grids/Islands`);
        return resp;
    } catch (error) {
        console.log("getAllIslandsJson error", error)
    }
}
//Récupérer ensuite les fichiers json de chaque Island:
export function getAllIslandsJsonFiles (islandName) {
    try {
        const resp = GitLabAxiosInstance.get(`tree/?recursive=true&pagination=none&ref=develop&path=Assets/StreamingAssets/grids/Islands/${islandName}/`);
        return resp;
    } catch (error) {
        console.log("getAllIslandsJson error", error)
    }
}




/* ************************
 * PLAYFAB API
**************************/
export function getUploadUrl(path) {
   
    const datasRequest = {
        "Key": path,
        "ContentType": "application/json"
    }
    try {
        //Avec l'API Playfab, créer une url qui servira de passerelle pour uploader un nouveau fichier.
        const resp = PFaxiosInstanceProd.post('Admin/GetContentUploadUrl', datasRequest);
        
        return resp;
    } catch (error) {
        console.error("l'url n'a pas été créée", error)
    }
}

export async function uploadFiles(url, file) {
    const axiosInstance = axios.create({
    baseURL: `https://8C50F.playfabapi.com/`,
    timeout: 10000,
    headers: {
        'X-Authorization': "6JOOBBEKRJKOYG8P4XBNK8YGB8U1MB8BPOKPT3OQFWJWFTMBOC",
        'Content-Type': 'application/json',
        'X-SecretKey': "6JOOBBEKRJKOYG8P4XBNK8YGB8U1MB8BPOKPT3OQFWJWFTMBOC",
    }
})
    try {
        //Faire une requête en put avec l'url récupérée, pour uploader le fichier:
        const result = await axiosInstance.put(url, file)
        return result;
    } catch (error) {
        console.error("le fichier n'a pas pu être chargé", error)
    }
}

/* ************************
 * AZURE STORAGE API
**************************/
// export async function createBlobFromBuffer(containerClient, blobName, buffer, uploadOptions){

//   // create blob client from container client
//   const blockBlobClient = await containerClient.getBlockBlobClient(blobName);

//   // Upload buffer
//   await blockBlobClient.uploadData(buffer, uploadOptions);

//   console.log(`${blobName} succeeded`);
// }

export async function createBlobFromString(containerClient, blobName, fileContentsAsString, uploadOptions){

  // Create blob client from container client
  const blockBlobClient = await containerClient.getBlockBlobClient(blobName);

  // Upload string
  await blockBlobClient.upload(fileContentsAsString, fileContentsAsString.length, uploadOptions);

  console.log(`${blobName} succeeded`);
}

export async function listContainers(blobServiceClient) {
    let containerClient;
    
    const options = {
    includeDeleted: false,
    includeMetadata: true,
    includeSystem: true,

    // filter with prefix
    prefix: 'grids'
  }

  for await (const containerItem of blobServiceClient.listContainers(options)) {

    // do something with containerItem
      containerClient = blobServiceClient.getContainerClient(containerItem.name);

  }
    return containerClient;
}

export async function listBlobsInsideContainer() {
    const containerClient = blobServiceClt.getContainerClient("grids");
    let allBlobs = []

    let blobs = containerClient.listBlobsFlat();
    allBlobs.push(blobs);
    return allBlobs;
}

/* ************************
 * FIREBASE STORAGE
**************************/
export async function sendGridsToFirebaseStorage(blob, folder) {
    try {
        const stored = await uploadFilesInCloudStorage(blob, folder);
        return stored
    } catch (error) {
        console.log("erreur de chargement sur Storage", error);
    }
}