import React from 'react';
import gan from '../../assets/images/Gan.png';

const Header = () => {
    return ( 
        <section className='w-full'>
            <div className='flex flex-row justify-start items-center'>
                <img src={gan} alt="logo picolors" className="w-52"/>
                <h1>Front admin picolors</h1>
            </div>
           
        </section>
     );
}
 
export default Header;