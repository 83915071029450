import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faFolder } from '@fortawesome/free-regular-svg-icons';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai';
import { useParams, Link } from 'react-router-dom';
import SpinnerGrids from '../Loaders/SpinnerGrids';
import Spinner from '../Loaders/Spinner';
import SearchBar from '../Searchbar';


const GridsByFolder = ({ allGrids }) => {
    let { island } = useParams();

    const [gridContent, setGridContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGrids, setIsLoadingGrids] = useState(false);
    const [searchText, setSearchText] = useState("");

    

    useEffect(() => {
       
    }, [island, isLoading, gridContent]);
  
    //console.log("all blobs", allBlobs);


    const getGridContent = async () => {
        // setIsLoadingGrids(true);
        let allContent = [];
   
        for (let i = 0; i < allGrids.length; i++) {
            const url = allGrids[i].path;
            const name = allGrids[i].name;
            
            await axios.get(url).then((response) => {
                const content = response.data;
                console.log(url)
                 allContent.push({name: name, content: content})
            })
            setGridContent(allContent);
            //setIsLoadingGrids(false);
            
        }
       
        
        return allContent;
    }

    //Récupérer tous les fichiers png dans un tableau:
    const getAllPng = allGrids.filter((file) => file.type === "png");

    const imgStyle = {
        imageRendering: "pixelated",
        width: "35%",
    }

    
//console.log('all png :', getAllPng);

   
    useEffect(() => {
        setIsLoadingGrids(true)
      //Récupération du contenu des grilles dans Firebase:
        getGridContent().then((res) => { 
            console.log("content:", res)
            setIsLoadingGrids(false)
        });
        
    }, []) 

   

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    }
    console.log(searchText)
    const filteredGrids = allGrids.filter(grid => grid.name.toLowerCase().includes(searchText.toLowerCase()));

    return ( 
        <section>
            <h1 className='text-white p-4 bg-black m-0'><FontAwesomeIcon icon={faFolder} className="mr-2" /> {island}</h1>
            <div className='flex flex-row items-center justify-between'>
                <Link to="/grids"><button className='bg-blue-400 p-3 text-white rounded-md w-32 m-2'>Retour</button></Link>
                <div className='w-1/3 m-2'>
                    <SearchBar searchText={searchText} onChange={handleSearch} />
                </div>
                
            </div>
            <div className='w-full p-2 container mx-auto'>
                {isLoading ? <Spinner/> : (
                    <ul>
                        {/* AFFICHE LE NOM DES GRILLES */}
                        {filteredGrids.map((grid, index) => {
                            if (grid.location === island && grid.type === 'json') {
                                        return (
                                            <li key={index} className="flex flex-row justify-between items-center border border-b-2 mb-3 py-3">
                                                {/* NOM DES GRILLES */}
                                                {!grid.name.endsWith('.png') && (
                                                     <div className='w-1/3 px-2 flex flex-row ml-4'>
                                                        <a href='/'><FontAwesomeIcon icon={faFileCode} className="mr-2"/> {grid.name}</a>
                                                    </div>
                                                )}

                                                {/* CONTENU DE LA GRILLE EN JSON */}
                                                <div className="text-left overflow-y-auto max-h-80">
                                                    <div><JSONPretty id='json-pretty' theme={JSONPrettyMon}></JSONPretty></div>
                                                </div>
                                               
                                                {/* IMAGES CORRESPONDANTS AUX GRILLES */}
                                                {getAllPng.map((img, index) => {
                                                    if (img.name.split('.')[0] === grid.name.split('.')[0]) {
                                                        return (
                                                            <div key={index} className='w-1/3 text-center flex flex-row justify-center'>
                                                                    <img src={img.path} alt="aperçu de la grille" className='mb-2' style={imgStyle}/>
                                                                    <a href={img.path}><FontAwesomeIcon icon={faDownload} /></a>
                                                            </div>
                                                            )
                                                    }
                                                    }
                                                        )
                                                
                                                }
                                              
                                            </li>
                                        )
                                    
                                   
                                
                
                               
                            }
                            }  
                        )}
                        
                    </ul>
                )}
                
                </div>
        </section>
     );
}
 
export default GridsByFolder;