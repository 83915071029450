import axios from "axios";

/*
    PLAYFAB ENDPOINTS
*/
export const PFaxiosInstanceProd = axios.create({
    baseURL: `https://${process.env.REACT_APP_PROD_ID}.playfabapi.com/`,
    timeout: 10000,
    headers: {
        'X-Authorization': process.env.REACT_APP_KEY_PROD,
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'X-SecretKey': process.env.REACT_APP_KEY_PROD,
    }
})


/*
    GITLAB ENDPOINTS - PICOLORS_2020
*/
export const GitLabAxiosInstance = axios.create({
    baseURL: "https://gitlab.com/api/v4/projects/30739790/repository/",
    timeout: 5000000,
    headers: {
        'Authorization': "Bearer glpat-zrt5BNZHtK6n2B9-SqqP",
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
});

/**
 *  AZURE STORAGE ENDPOINTS 
 */
export const AzureAxiosInstance = axios.create({
    baseURL: "https://picolors2.blob.core.windows.net/",
    timeout: 5000000,
    headers: {
        'Access-Control-Allow-Origin': "*",
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
})