import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import { Link } from "react-router-dom";
import Spinner from '../Loaders/Spinner';

const FoldersPage = ({allGrids}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [allFolders, setAllFolders] = useState([]);
    const [allGridsFolders, setAllGridsFolders] = useState([]);
    
    useEffect(() => {
        getAllGridsFolders();
    }, [allGrids])
    console.log("all Grids", allGrids);

    const getAllGridsFolders = () => {
        setIsLoading(true)
        let folders = [];
        if (allGrids.length > 0)
        {
            for (let i = 0; i < allGrids.length; i++) {
                //Récupérer seulement le nom du dossier:
                const folder = allGrids[i].location;
                folders.push(folder);
            }
            if (folders.length > 0) {
                setIsLoading(false);
                //console.log("folders", folders)
            }
            setAllGridsFolders(folders);
        }
        return folders;
    }
    

    return ( 
        <section className='h-screen'>
            <h1 className='text-white p-4 bg-black m-0'>Explorateur de grilles</h1>
            <div className="m-4 flex flex-col pt-4 container mx-auto bg-white bg-opacity-80">
                <h2 className='mb-6'>Firestore Islands and DailyGrids</h2>
                <div className="flex flex-row justify-around">
                    <div className='border border-2 border-gray-300 w-1/3 text-left pl-9 py-6 rounded-lg shadow-md'>
                        <h3>Liste des Islands Firebase :</h3>
                        {isLoading ? <Spinner /> : (
                            <ul>
                                {[...new Set(allGridsFolders)].sort().map((dir, index) => (
                                    <li key={index}><Link to={`${dir}`}><FontAwesomeIcon icon={faFolder} className="mr-2 text-blue-400"/>{dir}</Link></li>
                                ))}
                            </ul>
                        )}
                        
                    </div>
                    <div className='border border-2 border-gray-300 w-1/3 text-left pl-9 py-6 rounded-lg shadow-md'>
                        <h3>Liste des DailyGrids :</h3>
                        
                    </div>
                </div>

            </div>

           
        </section>
     );
}
 
export default FoldersPage;