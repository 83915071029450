import React, {useState, useEffect} from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../../Firebase/config.mjs';
import getPlayerData, { updatePlayerData } from '../../Utils/players';
import Header from '../Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import Modal from '@mui/material/Modal';
import style from './ModalStyle';
import { Box } from '@mui/material';
import JSONInput  from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/fr';
import { toast } from 'react-toastify';


const PlayerPage = () => {
    const [selectedValues, setSelectedValues] = useState({
        ColorsPutSecur: {},
        DailyGridsUnlocked:{},
        GridsDataSecur:{},
        NotifSecur: {},
        TilesUnlocked: {},
        achivementSecure: {},
        codexChromatiqueInfo: {},
        currencies: {}, 
        powersData: {}
    });
    const [selectedValue, setSelectedValue] = useState({});
    const [updatedDatas, setUpdatedDatas] = useState({});
    const [valueIsEmpty, setValueIsEmpty] = useState(false);
    const [selectedKey, setSelectedKey] = useState("");
    const [toggleJsonModal, setToggleJsonModal] = useState(false);
    const [user] = useAuthState(auth);
    const {id} = useParams();
 

useEffect(() => {
    //Récupération des datas du Player depuis PlayFab:
    getPlayerData(id).then(resp =>{ 
        //Si pas de datas:
        if (Object.keys(resp.data.Data).length === 0) {
            setSelectedValues({...selectedValues})
        } else {
            setSelectedValues({
                ColorsPutSecur: resp.data.Data.ColorsPutSecur.Value,
                DailyGridsUnlocked: resp.data.Data.DailyGridsUnlocked.Value,
                GridsDataSecur:resp.data.Data.GridsDataSecur.Value,
                NotifSecur: resp.data.Data.NotifSecur.Value,
                TilesUnlocked: resp.data.Data.TilesUnlocked.Value,
                achivementSecure: resp.data.Data.achivementSecure.Value,
                codexChromatiqueInfo: resp.data.Data.codexChromatiqueInfo.Value,
                currencies: resp.data.Data.currencies.Value, 
                powersData: resp.data.Data.powersData.Value
            });
            console.log("playfab:", resp.data.Data)
        }
    })
}, [user, id, selectedValues])

//console.log("sleected datas:", selectedValues)

//Afficher les datas json dans le modal
const showJsonValues = (datas, key) => {
    //console.log("show key", key);
    console.log("show json datas:", datas)
    const val = JSON.parse(datas);
    console.log("val", val)
     setSelectedKey(key);
    //JSON.parse(datas)
    setToggleJsonModal(prevState => !prevState);
    if (Object.keys(val).length === 0) {
        //Si pas de datas, mettre un input vide
        setSelectedValue({
            keyName: ""
        });
    } else {
       
        //console.log("datas selected", datas.Value);
        if (Object.values(val) === "{}") {
            setSelectedValue({});
        }
        
        if (Object.keys(val).length !== 0) {
            //Données d'un input:
            setSelectedValue(val);
        } else {
            setSelectedValue({
                keyName: ""
            });
            setValueIsEmpty(true);
        }
    }
    
   
}

const handleCloseModal = () => {
    setToggleJsonModal(prevState => !prevState);
}



useEffect(() => {
  setSelectedValues(selectedValues)
}, [selectedValues, selectedValue])

const handleChangeJsonValues = (event) => {
    //console.log("event", event.jsObject)
    setSelectedValue(event.jsObject);
}


const updateJsonValues = () => {
    //Fermer le modal
    setToggleJsonModal(prevState => !prevState);

    //Mettre à jour selectedValues avec les nouvelles données JSON:
    for (const key in selectedValues) {
        if (Object.hasOwnProperty.call(selectedValues, key)) {
            //console.log("selected key", selectedValues[selectedKey])
            //console.log('update selected value', selectedValue);
            if (key === selectedKey) {
                setSelectedValues({...selectedValues, [key]: selectedValue})
            }
        }
    }
}


const RenderInputsFromKey = Object.entries(selectedValues).map(([key, value], index) => 
    (<div className='flex flex-row items-center' key={index}>
        <div className='p-4 w-full text-left'>
                <label className='mr-4 font-bold capitalize'>{key}:</label>
                <div className='flex flex-row'>
                    <p data-testid="countValue" className="border-2 border-gray-200 w-full mr-4 pt-2">{JSON.stringify(value)}</p>
                    <button data-testid="modifier" onClick={() => showJsonValues(value, key)} className='bg-blue-400 rounded-lg shadow-lg p-2 text-white'>Modifier</button>
                </div>
        </div>
    </div>
    )
)




//Après avoir modifié les values JSON, les transformer en string, pour le bon formatage dans Playfab.
const stringifyDatas = () => {
     for (const key in selectedValues) {
        if (Object.hasOwnProperty.call(selectedValues, key)) {
            const element = selectedValues[key];
            if (typeof element === 'object') {
                setSelectedValues({...selectedValues, [key]: JSON.stringify(element)})
            }
        }
    }
}

useEffect(() => {
   stringifyDatas()
}, [selectedValues])


//console.log("selected ONE val:", selectedValue)
const sendUpdatedDatas = () => {
   console.log("send selected values: ", selectedValues)
    try {
        updatePlayerData(selectedValues, id).then(resp => {
            console.log("données envoyées à playfab", resp)
            toast.success("Vos données ont été enregistré dans la base de données PLAYFAB !")
        });
    } catch (error) {
        console.error("les données n'ont pas été mises à jour...", error);
        toast.error("Les données n'ont pas été sauvegardé, veuillez recommencer...")
    }
     
}


    return ( 
        <section data-testid="selectedValues" className='bg-black bg-cover bg-center w-full py-5 pl-28 pr-5 min-h-screen'>
            <article className='bg-white shadow-inner h-full rounded-2xl'>
                 {user ? <Header/> : null}
                  <h1 data-testid="playerId">Player ID: <span className='font-body'>{id}</span></h1>
                  <div className='w-full text-left pl-8'>
                     <Link to="/dashboard" className='text-left'><FontAwesomeIcon icon={faAnglesLeft} />  Retour à la liste des Players</Link>
                  </div>
                  
                   
                  <div className="flex flex-row w-full p-4">
                    <div className='flex flex-col w-full'>
                        {RenderInputsFromKey}
                    </div>
                  </div>
                <div className="flex flex-row justify-center pb-5">
                    <button onClick={sendUpdatedDatas} className='bg-yellow-400 rounded-md px-4 py-2 mx-3'>Enregistrer</button>
                    <Link to='/dashboard' className='bg-black text-white rounded-md px-4 py-2 mx-3'>Annuler</Link>
                </div>
            </article>
            {toggleJsonModal && 
                <Modal
                    data-testid="toggleModal"
                    open={toggleJsonModal}
                    onClose={handleCloseModal}
                >
                    <Box sx={style}>
                        <h3 className='font-bold capitalize'>{selectedKey} :</h3>
                            <div className="w-full">
                                <label>Modifier les données JSON :</label>
                                <JSONInput
                                    data-testid="selectedValues"
                                    placeholder={selectedValue} // data to display
                                    theme="light_mitsuketa_tribute"
                                    locale={locale}
                                    colors={{
                                        string: "#DAA520" // overrides theme colors with whatever color value you want
                                    }}
                                    height="100%"
                                    onChange={handleChangeJsonValues}
                                />
                            </div>
                        <div className='w-full flex flex-row justify-center mt-5'>
                            <button onClick={updateJsonValues} className='bg-yellow-400 rounded-md px-4 py-2'>Valider</button>
                        </div>
                       
                    </Box>
                </Modal>
            }
           
           
        </section>
     );
}
 
export default PlayerPage;