import React, {useEffect, useState} from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, db, logout } from '../../Firebase/config.mjs';
import { query, collection, getDocs, where } from "firebase/firestore";
import Header from '../Header';
import { DataGrid} from '@mui/x-data-grid';
import { getAllPlayers } from '../../Utils/players';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'

const HomePage = () => {
    const [user, loading, error] = useAuthState(auth);
    const [allPlayers, setAllPlayers] = useState([]);
    const [name, setName] = useState("");
    const navigate = useNavigate();

    const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    getAllPlayers().then(resp => setAllPlayers(resp.data.PlayerProfiles));
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
  }, [user, loading]);

console.log("allPlayers", allPlayers)
  const columns = [
  { 
    field: 'PlayerId', 
    headerName: 'Player ID', 
    width: 170,
    renderCell: params => {
      return (
        <Link to={`/player/${params.value}`} className="text-blue-500">{params.value}</Link>
      )
    }
  },
  { 
    field: 'LastLogin', 
    headerName: 'Last Login', 
    width: 250, 
    renderCell: params => {
      const dateFormatted = moment(params.value).format('DD/MM/YYYY');
      return dateFormatted;
    }
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 250,
    renderCell: params => {
      const dateFormatted = moment(params.value).fromNow();
      return dateFormatted;
    }
  },
 
];



    return ( 
        <section className='bg-black w-full h-screen-full py-5 pl-28 pr-5 text-left'>
            <article className='bg-white shadow-inner h-screen-full rounded-2xl'>
                 {user ? <Header/> : null}
                  <div className='w-full text-right pr-4'>
                      <p>Vous êtes connecté :</p>
                      <div>{user?.email}</div>
                      <button className="bg-red-400 rounded-lg px-3 py-1 mt-2 text-white" onClick={logout}>
                      Déconnexion
                      </button>
                  </div>
                  
                    <h1 className='text-center'><FontAwesomeIcon icon={faList}/> Players List</h1>
                    <div>
                      <DataGrid
                        rows={allPlayers}
                        getRowId={(row) => row.PlayerId}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        sx={{
                          backgroundColor: "#FFFFFF",
                          color: "#000000",
                          height: "800px",
                          margin: "12px"
                        }}
                      />
                    </div>
                   
            </article>
        </section>
       
     );
}

export default HomePage;