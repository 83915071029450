import React from 'react';
import './nav.css';
import {bubble as Menu} from 'react-burger-menu';
import { NavHashLink } from 'react-router-hash-link';

const navigation = [
    {url: "dashboard", name:"Players datas"},
    {url: "grids", name:"Explorateur de grilles"}
]

const Nav = () => {
    return (
        <Menu left width={ '20%' }>
            {navigation.map((item, index) => (
                <NavHashLink
                    key={index}
                    smooth
                    to={`/${item.url}`}
                    className="py-3 p-2 w-full bg-red-400 mt-4 underline text-white font-bold"
                >
                    {item.name}
                </NavHashLink>
            ))}
        </Menu>
    )
}

export default Nav;